<template>
  <div v-if="editor">
    <NuxtImg
      v-if="personImage"
      class="rounded-full bg-black bg-opacity-10 w-15 h-15 overflow-hidden bg-cover bg-center"
      :src="personImage"
      :width="64"
      :height="64"
    />
    <img
      src="~/assets/icons/person-fallback.svg?url"
      class="rounded-full bg-black bg-opacity-10 w-15 h-15 overflow-hidden bg-cover bg-center"
      v-else
    />
    <div class="list-title-xxxs mt-1">
      {{ editor.Name }}
    </div>
    <div class="list-label opacity-50 mb-3">
      {{ editor.Title }}
    </div>
    <div
      v-if="editor.Phone && editor.Phone.length > 5"
      class="list-label opacity-50 flex items-center"
    >
      <img
        src="~/assets/icons/phone.svg?url"
        class="w-4 h-4 opacity-50 inline-block mr-1"
      />{{ editor.Phone }}
    </div>
    <template v-if="route.name?.toString().includes('debate')">
      <div class="list-label opacity-50 flex items-center mt-1">
        <img
          src="~/assets/icons/mail.svg?url"
          class="w-4 h-4 opacity-35 inline-block mr-1"
        /><a :href="'mailto:' + config.public.site.debateEmail">{{
          config.public.site.debateEmail
        }}</a>
      </div>
    </template>
    <template v-else>
      <div class="list-label opacity-50 flex items-center mt-1">
        <img
          src="~/assets/icons/mail.svg?url"
          class="w-4 h-4 opacity-35 inline-block mr-1"
        /><a :href="'mailto:' + editor.Email">{{ editor.Email }}</a>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig()
const route = useRoute()

const props = defineProps({
  editor: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const personImage = computed(() => {
  return props.editor.Image
    ? `${config.public.site.legacydomain}/images/Writers/${appendToFilename(
        props.editor.Image,
        '-2'
      )}`
    : undefined
})
</script>
